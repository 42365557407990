import { lazy } from 'react';
import { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';

// const CyberSecurityCourse = lazy(() =>
//     import("./pages/CyberSecurityCourse/App")
// );
const Navbar = lazy(() => import('./components/Navbar'));
const Footer = lazy(() => import('./components/Footer'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));

function App() {
    return (
        <Suspense fallback={null}>
            <Switch>
                {/* <Route path="/cybersecuritycourse">
                    <CyberSecurityCourse />
                </Route> */}
                <Route path="/">
                    <div className="app">
                        <ScrollToTop />
                        <Navbar />
                        <div className="navbar_mixins" />
                        <Dashboard />
                        <Footer />
                    </div>
                </Route>
            </Switch>
        </Suspense>
    );
}

export default App;
